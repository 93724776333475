import Author from '../../Author'
import CardBlogPost from '../../CardBlogPost'
import StickyBar from '../../StickyBar'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as React from 'react'
import { Helmet } from 'react-helmet'

class Doggos extends React.Component {
  render() {
    const offset = this.props.location.state
      ? this.props.location.state.offset
      : 0
    const { site, post, articles } = this.props.data

    console.log('post:', post)
    console.log('articles:', articles)

    return (
      <div className={style.blogPost}>
        {/* Meta */}
        <Helmet>
          <title>{`${post.frontmatter.postTitle} - ${site.siteMetadata.title}`}</title>
          <meta property='og:title' content={post.frontmatter.meta.title} />
          <meta
            property='og:description'
            content={post.frontmatter.meta.description}
          />
          <meta
            name='viewport'
            content='width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no'
          />
          <link
            rel='canonical'
            href={`https://doggos.com/${post.frontmatter.id}`}
          />
          <meta
            property='og:image'
            content={
              post.frontmatter.image.childImageSharp.gatsbyImageData?.src
            }
          />
        </Helmet>

        {/* Hero Image */}

        {post.frontmatter.image.childImageSharp && (
          <div className={style.coverPhoto}>
            <GatsbyImage image={getImage(post.frontmatter.image)} />
          </div>
        )}

        {/* Post Title */}

        <h1 className={style.titleOfBlogPost}>{post.frontmatter.postTitle}</h1>

        <div className={style.flexContainer}>
          {post.frontmatter.date && (
            <p data-nosnippet className={style.date}>
              {post.frontmatter.date}
            </p>
          )}
        </div>

        <div className={style.markdown}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </div>

        <Author listing={this.props.pageContext.listing} />

        {/* Continue reading */}

        {articles.edges.length > offset + 1 ? (
          <div className={style.cardContainer}>
            <StickyBar>
              <h3 className={style.feed}>Read more</h3>
            </StickyBar>
            <div className={style.blogPostContainer}>
              {articles.edges.slice(offset + 1).map((edge, index) => {
                const article = edge.node.frontmatter
                return (
                  <CardBlogPost
                    key={article.id}
                    image={article.image}
                    title={article.postTitle}
                    link={{
                      pathname: `/${article.id}/`,
                      state: { offset: offset + 1 + index },
                    }}
                  />
                )
              })}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default Doggos
