// extracted by mini-css-extract-plugin
export var blogPost = "style-module--blogPost--88b1f";
export var blogPostContainer = "style-module--blogPostContainer--f614d";
export var cardContainer = "style-module--cardContainer--672a7";
export var coverPhoto = "style-module--coverPhoto--e35e6";
export var date = "style-module--date--654ab";
export var feed = "style-module--feed--0c870";
export var flexContainer = "style-module--flexContainer--8eee5";
export var fullWidthImageSliderWrapper = "style-module--fullWidthImageSliderWrapper--a1ef4";
export var markdown = "style-module--markdown--3540b";
export var readTime = "style-module--readTime--c8601";
export var titleOfBlogPost = "style-module--titleOfBlogPost--8835e";