import * as React from 'react'
import Template from '../Template'
import Doggos from './Doggos/index'

function PageBlogPost(props) {
  return (
    <Template>
      {process.env.GATSBY_SITE === 'doggos' && <Doggos {...props} />}
    </Template>
  )
}

export default PageBlogPost
