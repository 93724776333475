// extracted by mini-css-extract-plugin
export var author = "style-module--author--13c37";
export var authorContainer = "style-module--authorContainer--8afa0";
export var badge = "style-module--badge--c79ff";
export var category = "style-module--category--81e0e";
export var content = "style-module--content--3d361";
export var image = "style-module--image--c131a";
export var legalese = "style-module--legalese--851cd";
export var listing = "style-module--listing--bc082";
export var popoverContent = "style-module--popoverContent--7b090";
export var readers = "style-module--readers--c5619";
export var row = "style-module--row--3149f";